"use client";

import { Editor } from "@tiptap/react";
import { Button } from "../Button";
import { Tooltip } from "../Tooltip";
import { Space } from "../Space";
import { Flex } from "../Flex";
import { Select } from "../Select";
import {
  BoldOutlined,
  BorderBottomOutlined,
  BorderRightOutlined,
  DeleteOutlined,
  InsertRowBelowOutlined,
  InsertRowRightOutlined,
  ItalicOutlined,
  LinkOutlined,
  OrderedListOutlined,
  StrikethroughOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useTranslations } from "next-intl";
import { Namespace } from "@/src/i18n/namespace";
import { SetLinkModal } from "./SetLinkModal";
import { useState } from "react";
import { theme } from "../theme";

enum HeadingValue {
  Heading1 = "H1",
  Heading2 = "H2",
  Heading3 = "H3",
  Paragragh = "Paragragh",
}

export const EditorMenuBar = ({ editor }: { editor?: Editor | null }) => {
  const {
    token: { colorIcon },
  } = theme.useToken();
  const tCommon = useTranslations(Namespace.COMMON);
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

  if (!editor) {
    return null;
  }

  const getSelectedHeading = () => {
    if (editor.isActive("heading", { level: 1 })) {
      return HeadingValue.Heading1;
    }
    if (editor.isActive("heading", { level: 2 })) {
      return HeadingValue.Heading2;
    }
    if (editor.isActive("heading", { level: 3 })) {
      return HeadingValue.Heading3;
    }
    return HeadingValue.Paragragh;
  };

  const options = [
    {
      label: (
        <h1
          style={{ fontSize: 18, fontWeight: 500 }}
        >{`${tCommon("textEditor.heading")} 1`}</h1>
      ),
      text: `${tCommon("textEditor.heading")} 1`,
      value: HeadingValue.Heading1,
    },
    {
      label: (
        <h2
          style={{ fontSize: 16, fontWeight: 500 }}
        >{`${tCommon("textEditor.heading")} 2`}</h2>
      ),
      text: `${tCommon("textEditor.heading")} 2`,
      value: HeadingValue.Heading2,
    },
    {
      label: (
        <h3
          style={{ fontSize: 15, fontWeight: 500 }}
        >{`${tCommon("textEditor.heading")} 3`}</h3>
      ),
      text: `${tCommon("textEditor.heading")} 3`,
      value: HeadingValue.Heading3,
    },
    {
      label: <div style={{ fontSize: 12 }}>{tCommon("textEditor.normal")}</div>,
      text: tCommon("textEditor.normal"),
      value: HeadingValue.Paragragh,
    },
  ];

  return (
    <div className="rich-text-editor-header">
      <Flex justify="space-between" className="w-full">
        <Space size="large">
          <div>
            <Select
              value={getSelectedHeading()}
              onChange={(v) => {
                if (v === HeadingValue.Heading1) {
                  editor.chain().focus().toggleHeading({ level: 1 }).run();
                } else if (v === HeadingValue.Heading2) {
                  editor.chain().focus().toggleHeading({ level: 2 }).run();
                } else if (v === HeadingValue.Heading3) {
                  editor.chain().focus().toggleHeading({ level: 3 }).run();
                } else {
                  editor.chain().focus().setParagraph().run();
                }
              }}
              labelRender={() =>
                options.find((o) => o.value === getSelectedHeading())?.text
              }
              options={options}
              dropdownStyle={{ width: 200 }}
            />
          </div>
          <div className="flex gap-[1px]">
            <Tooltip placement="top" title={tCommon("textEditor.italic")}>
              <Button
                type={editor.isActive("italic") ? "primary" : "text"}
                icon={<ItalicOutlined />}
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={!editor.can().chain().focus().toggleItalic().run()}
              />
            </Tooltip>
            <Tooltip placement="top" title={tCommon("textEditor.bold")}>
              <Button
                type={editor.isActive("bold") ? "primary" : "text"}
                icon={<BoldOutlined />}
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run()}
              />
            </Tooltip>
            <Tooltip
              placement="top"
              title={tCommon("textEditor.strikethrough")}
            >
              <Button
                type={editor.isActive("strike") ? "primary" : "text"}
                icon={<StrikethroughOutlined />}
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={!editor.can().chain().focus().toggleStrike().run()}
              />
            </Tooltip>
          </div>

          <div className="flex gap-[1px]">
            <Tooltip placement="top" title={tCommon("textEditor.numberList")}>
              <Button
                type={editor.isActive("orderedList") ? "primary" : "text"}
                icon={<OrderedListOutlined />}
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
              />
            </Tooltip>
            <Tooltip placement="top" title={tCommon("textEditor.bulletList")}>
              <Button
                type={editor.isActive("bulletList") ? "primary" : "text"}
                icon={<UnorderedListOutlined />}
                onClick={() => editor.chain().focus().toggleBulletList().run()}
              />
            </Tooltip>
          </div>

          <div className="flex gap-[1px]">
            <Tooltip placement="top" title={tCommon("textEditor.setLink")}>
              <Button
                type="text"
                icon={<LinkOutlined />}
                disabled={editor.state.selection.empty}
                onClick={() => setIsLinkModalOpen(true)}
              />
            </Tooltip>
          </div>

          <div className="flex gap-[1px]">
            <Tooltip placement="top" title={tCommon("textEditor.insertTable")}>
              <Button
                type="text"
                icon={<TableOutlined />}
                onClick={() =>
                  editor
                    .chain()
                    .focus()
                    .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                    .run()
                }
              />
            </Tooltip>

            <Tooltip placement="top" title={tCommon("textEditor.addRow")}>
              <Button
                type="text"
                icon={<InsertRowBelowOutlined />}
                onClick={() => editor.chain().focus().addRowAfter().run()}
                disabled={!editor.can().addRowAfter()}
              />
            </Tooltip>

            <Tooltip placement="top" title={tCommon("textEditor.addColumn")}>
              <Button
                type="text"
                icon={<InsertRowRightOutlined />}
                onClick={() => editor.chain().focus().addColumnAfter().run()}
                disabled={!editor.can().addColumnAfter()}
              />
            </Tooltip>

            <Tooltip placement="top" title={tCommon("textEditor.removeRow")}>
              <Button
                type="text"
                icon={<BorderBottomOutlined />}
                onClick={() => editor.chain().focus().deleteRow().run()}
                disabled={!editor.can().deleteRow()}
              />
            </Tooltip>

            <Tooltip placement="top" title={tCommon("textEditor.removeColumn")}>
              <Button
                type="text"
                icon={<BorderRightOutlined />}
                onClick={() => editor.chain().focus().deleteColumn().run()}
                disabled={!editor.can().deleteColumn()}
              />
            </Tooltip>
          </div>
        </Space>
        <div>
          <Tooltip
            placement="top"
            title={tCommon("textEditor.clearAllContent")}
          >
            <Button
              type="text"
              icon={
                <DeleteOutlined color={colorIcon} style={{ opacity: "45%" }} />
              }
              onClick={() => editor.commands.clearContent(true)}
            />
          </Tooltip>
        </div>
      </Flex>

      <SetLinkModal
        editor={editor}
        open={isLinkModalOpen}
        setOpen={setIsLinkModalOpen}
      />
    </div>
  );
};
