"use client";

import { Typography, theme } from "antd";
import { TextProps } from "antd/es/typography/Text";
import { CSSProperties } from "react";

export { default as Title } from "antd/es/typography/Title";

const { Paragraph, Link } = Typography;

type Size = "XL" | "LG" | "MD" | "SM" | "XS";
type Variant = "default" | "description";
interface TextSizeProps extends TextProps {
  children: React.ReactNode;
  size?: Size;
  variant?: Variant;
}

export const Text = ({
  size = "MD",
  variant = "default",
  children,
  ...props
}: TextSizeProps) => {
  const {
    token: {
      fontSizeSM,
      lineHeightSM,
      fontSizeLG,
      lineHeightLG,
      fontSizeXL,
      colorTextDescription,
    },
  } = theme.useToken();

  const sizeStyle: Record<Size, { fontSize?: number; lineHeight?: number }> = {
    XL: { fontSize: fontSizeXL, lineHeight: lineHeightLG },
    LG: { fontSize: fontSizeLG, lineHeight: lineHeightLG },
    MD: {},
    SM: { fontSize: fontSizeSM, lineHeight: lineHeightSM },
    XS: { fontSize: 10, lineHeight: lineHeightSM },
  };

  const variantStyle: Record<Variant, CSSProperties> = {
    default: {},
    description: { color: colorTextDescription },
  };

  return (
    <Typography.Text
      {...props}
      style={{ ...sizeStyle[size], ...variantStyle[variant], ...props.style }}
    >
      {children}
    </Typography.Text>
  );
};

export { Paragraph, Link };
