import React from "react";
import { Text } from "./Typography";
import { Flex } from "./Flex";
import { theme } from "antd";

export { Form } from "antd";
export type {
  FormProps,
  FormRule,
  FormItemProps,
  FormListOperation,
  FormListFieldData,
} from "antd";
export type { Rule, FormListProps } from "antd/es/form";

interface FormLabelProps {
  id: string;
  required?: boolean;
  children: React.ReactNode;
}

export function FormLabel({ id, required, children }: FormLabelProps) {
  const {
    token: { marginXXS, paddingXS },
  } = theme.useToken();
  return (
    <label
      className="inline-flex"
      htmlFor={id}
      style={{
        paddingBottom: paddingXS,
      }}
    >
      {required ? (
        <Text
          style={{
            marginRight: marginXXS,
          }}
          type="danger"
        >
          *
        </Text>
      ) : null}

      {children}
    </label>
  );
}

interface FormHelperTextProps {
  errorMessage?: string;
  help?: React.ReactNode;
  className?: string;
}

export function FormHelperText({
  errorMessage,
  help,
  className,
}: FormHelperTextProps) {
  return (
    <Flex gap="large" justify="space-between" className={className}>
      <Text size="SM" type="danger">
        {errorMessage}
      </Text>
      {help}
    </Flex>
  );
}
